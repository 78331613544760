body{
	font-family: "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
ul,li{
	list-style:none;
	padding: 0;
	margin: 0;
}
p{
	margin: 0;
}
a{
	text-decoration: none;
}
img{
	max-width:100%;
}
.c-content{
	width: 800px;
	margin: auto;
}
.c-heading{
	font-weight: bold;
	display: block;


	text-align: center;
	color: #000;
	margin:0 auto 70px;
	font-size:2.7rem;
}
.c-heading br{
	display: none;
}
.marker{
		background: linear-gradient(transparent 60%, #fddc0d 60%);
			width: fit-content;
}
.org{
	color: #e75513;
}
.header{
	text-align: center;
	padding: 15px 0;
}
.header h1{
	width: 200px;
	margin: auto;
}	
.mv{
	text-align: center;
	background: rgb(253,220,13);
background: linear-gradient(90deg, rgba(253,220,13,1) 0%, rgba(254,232,101,1) 50%, rgba(253,220,13,1) 100%);
padding: 70px 0 0;
}
.mv_inner{
	padding:0 5%;
}

.company-list{
	text-align: center;
	padding: 150px 0;
}

.trouble{
	background: #f6f6f6;
	padding: 150px 0;
}
.trouble .list .text{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	background: #fff;
	box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.3);
	border-radius: 10px;
	font-weight: bold;
	padding: 15px 30px;
	font-size: 1.5rem;
	    margin: 0 0 20px;
}
.trouble .list .text:last-child{
	margin-bottom: 0;
}
.trouble .list .text:before{
	content:"";
	background:url(../img/trouble_icon.jpg) no-repeat;
	background-size:100% auto;
	display: block;
	width: 40px;
	height: 40px;
	vertical-align: -11px;
    margin-right: 13px;
}

.point{
	background: #fddc0d;
	padding: 60px 0 150px 0;
}
.point .logo{
	text-align: center;
	padding: 20px 50px;
	width: 300px;
	margin:0 auto;
}
.point .marker{
	background: linear-gradient(transparent 60%, #ffffff 60%);
	
}
.point .content{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content:space-between;
}
.point .c-block{
	text-align: center;
	width: 22%;
}
.point .text{
	font-weight: bold;
	margin: 30px 0 0;
	font-size: 1.3rem;
}
.flow{
	padding: 150px 0;
}
.flow .content{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content:space-between;
	margin:130px 0 0;
}
.flow .c-block{
	position: relative;
	text-align: center;
	border-radius:10px;
	border:1px solid #003c64;
	padding: 40px 30px;
	width: 27%;
}
.flow .c-block:after{
	content:"";
	background:url(../img/flow_arrow.png) no-repeat;
    display: inline-block;
    background-size: 100% auto;
    width: 65px;
    height: 40px;
    margin: auto;
    position: absolute;
    top:0;
    bottom:0;
    right:-29%;
}
.flow .c-block:last-child:after{
	content:"";
	background:none;
	width: 0;
}
.flow h3{
	font-weight: bold;
	margin: 20px 0;
}
.flow .step{
	margin:-80px auto 0;
	width: 85px;
}
.flow .pic{
	width: 70%;
	margin:20px auto 30px;
}
.flow p{
	font-size: 1.3rem;
}
.voice{
	background: #fddc0d;
	padding: 150px 0;	
}
.voice .marker{
	background: linear-gradient(transparent 60%, #ffffff 60%);
}
.voice .content{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content:space-between;
	flex-wrap: wrap;
}
.voice .c-block{
	border-radius:10px;
	background: #fff;
	box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.3);
	width: 47.5%;
	padding: 40px;
	margin:0 0 60px;
}
.voice .block-top{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
}
.voice .pic{
	width: 170px;
	margin:0 30px 0 0;
}
.voice h3{
	line-height: 1.65;
	font-size: 1.4rem;
	font-weight: bold;
	margin:0 0 10px;
}
.voice .text{
	margin: 10px 0 0;
	line-height: 1.8;
	font-size: 1.1rem;
}
.message{
	background:url(../img/message_bg.jpg) no-repeat center top;
	background-size:cover;
	color: #212529;
	padding: 90px 0;
}
.message p{
	font-size: 1.4rem;
	margin-bottom: 40px;
	line-height: 2;
}
.faq{
	padding: 150px 0;
}
.faq .c-block{
	margin-bottom: 20px;
}
.faq .c-block:last-child{
	margin-bottom: 0;
}
.faq .q{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	background: #fddc0d;
	padding: 15px;
	font-size: 1.5rem;
}
.faq .q .icon{
	font-weight: bold;
	text-align: center;
	background: #fff;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	line-height: 45px;
	margin:0 20px 0 0;
	font-size: 1.7rem;
	flex-shrink: 0;
}

.faq .q .text{
	font-weight: bold;
}
.faq .a{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	padding: 15px;
}
.faq .a .icon{
	font-weight: bold;
	text-align: center;
	background: #003c64;
	color: #fff;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	line-height: 45px;
	margin:0 20px 0 0;
	font-size:1.7rem;
	flex-shrink: 0;
}
.faq .align-items-center-pc{
	align-items: center;
}
.about{
	background: #fddc0d;
	padding: 150px 0;	
}
.about .marker{
	background: linear-gradient(transparent 60%, #ffffff 60%);
}
.about .content{
	background: #fff;
	box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.3);
	padding: 50px;
}
.about .c-block{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin-bottom: 40px;
}
.about .c-block:last-child{
	margin-bottom: 0;
}
.about .th{
	width: 110px;
}
.footer{
	text-align: center;
	padding: 40px 0 200px;
}
.footer .text{
	font-weight: bold;
}
.footer .logo{
	width: 180px;
	margin:10px auto 20px;
}

.sticky{
	width: 100%;
	position:fixed;
	bottom:0;
	background: rgba(253,220,13,.9);
	padding: 30px 0;
	
}
.sticky a{
	position: relative;
-webkit-transition: opacity 0.5s ease-out;
-moz-transition: opacity 0.5s ease-out;
-ms-transition: opacity 0.5s ease-out;
transition: opacity 0.5s ease-out;	
	font-weight: bold;
	background: #e75513;
	display: block;
	text-align: center;
	color: #fff;
	font-size:2.7rem;
	padding: 20px 0;
	border-radius: 60px;
}
.sticky a:after{
    content: '';
	width: 18px;
    height: 18px;
    margin-top: -10px;
    border-top: solid 3px #fff;
    border-right: solid 3px #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 60px;
}
.sticky a:hover{
	opacity: .7;
-webkit-opacity: .7;
-moz-opacity: .7;
filter: alpha(opacity=70);	/* IE lt 8 */
-ms-filter: "alpha(opacity=70)"; /* IE 8 */
}
@media screen and (max-width: 1176px) {
	
.flow h3 {
    font-weight: bold;
    margin: 20px 0;
    font-size: 1.4rem;
}
.flow .c-block:after {
    content: "";
    width: 45px;
    height: 30px;

}
}
@media screen and (max-width: 850px) {
.c-content{
	width: auto;
}
.point .content{
	flex-wrap: wrap;
}
.point .c-block{
	width:42%;
	margin-bottom: 40px;	
}
.flow .content{
	flex-wrap: wrap;
	margin: 80px 0 0;
}
.flow .c-block {
    width: 100%;
    margin-bottom: 100px;
}
.flow .c-block:last-child{
	margin-bottom: 0;
}
.flow .step {
    width: 70px;
}
.flow .pic {
    width: 55%;
}
.flow h3 {
    margin: 15px 0;
    font-size: 1.3rem;
}
.flow p {
    font-size: 1rem;
}
.flow .c-block:after {
    content: "";
    width: 35px;
    height: 20px;
    bottom: -39px;
    right:0;
    left:0;
    margin:auto auto 0;
    transform: rotate( 90deg );
}
.voice .c-block{
	width: 100%;
	padding: 30px;
	margin-bottom: 30px;
}
.voice .c-block:last-child{
	margin-bottom: 0;
}
.voice .pic {
    width: 37%;
    margin: 0 15px 0 0;
}
.voice .heading{
	width: 60%;
}
.voice h3 {
    line-height: 1.5;
    font-size: 1.2rem;
    margin: 0;
}
.voice .text{
	font-size: .95rem;
}
.flow .content,
.point .content,
.voice .content {
    width: 80%;
    margin: auto;
}
.sticky{
	background:none;
	padding: 15px 0;
}
.sticky a{
    font-size: 1.4rem;
	box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.5);
	width: 80%;
	margin: auto;
	padding: 10px 0;
}
}
@media screen and (max-width: 767px) {

.c-heading {
    margin: 0 auto 40px;
    font-size: 1.8rem;
    line-height: 1.5;
}
.c-heading br{
	display: block;
}
.company-list,
.trouble,
.point,
.flow,
.voice,
.about,
.faq{
	padding: 50px 0;
}
.mv{
	padding: 0;
}
.mv_inner {
    padding: 0;
}
.flow .content,
.point .content,
.voice .content {
    width: auto;
}	
.header{
	padding: 7px 0;
}	
.header h1{
	width: 120px;
}	
.trouble .list .text{
	padding: 12px 20px;
	font-size: 1.2rem;
}
.trouble .list .text:before {
    content: "";
    width: 25px;
    height: 25px;
    margin-top: 2px;
    margin-right: 10px;
    flex-shrink: 0;
}
.point{
	padding-top: 20px;
}
.point .c-block{
	width:48%;
	margin-bottom: 30px;	
}



.point .text {
    font-size: 1rem;
    margin: 15px 0 0;
}
.point .logo {
    width: 250px;
    margin: 0 auto;
}

.message{
	padding: 30px 10px;
}
.message p {
    font-size: 1.1rem;
    margin-bottom: 30px;
    line-height: 2;
}
.faq .q {
    padding: 10px;
    font-size: 1rem;
}
.faq .a{
	padding: 10px;
	font-size: .95rem;
}
.faq .q .icon,
.faq .a .icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 10px 0 0;
    font-size: 1rem;
}
.faq .align-items-center-pc{
	align-items: initial;
}
.about .content {
    padding: 30px 20px;
}
.about .c-block{
	flex-wrap: wrap;
	margin-bottom: 23px;
}
.about .th {
	font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
}
.footer{
	padding: 20px 0 90px;
}
.sticky a:after {
    content: '';
    width: 10px;
    height: 10px;
    margin-top: -5px;
    top: 50%;
    right: 20px;
}
}
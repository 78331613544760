@charset "UTF-8";

html {
  height: 100%;
}

body {
  height: 100%;
}

div#root {
  height: 100%;
}

#root>div {
  height: 100%;
}
div.overley{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 999;
  display: none;
}
div.overley-inner{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 1000;
  display: none;
}
.b-n-article-wrapper{
  position: relative;
  /* height: 10px;
  background-color: aquamarine; */
}
header.headerChatForm {
  height: 100px;
  /* max-height: 80%; */
  content: inherit;
  position: absolute;
  z-index: 100;
  background-color: none;
  width: 100%;
}


header.headerChatForm>h1>div {
  display: block;
}

header.headerChatForm img {
  position: relative;
  top: 0;
  height: 50px;
  margin-left: 30px;
}

p.color-grey {
  font-size: 10px;
  line-height: 11px;
  color: gray;
}
p.color-red {
  font-size: 13px;
  line-height: 13px;
  color: red;
}
p.color-white {
  color: white;
}

header.headerChatForm>h1>div>div {
  font-size: 18px;
  background-color: orangered;
  color: #fff;
  padding: 3px 31px;
  border-radius: 11px;
  height: 27px;
  margin-top: 10px;
  margin-left: 28px;
  letter-spacing: 7px;
  width: 100px;
}
header.headerChatForm-b {
  height: 100px;
  /* max-height: 80%; */
  content: inherit;
  position: absolute;
  z-index: 100;
  background-color: none;
  width: 100%;
}


header.headerChatForm-b>h1>div {
  display: block;
}

header.headerChatForm-b img {
  position: relative;
  top: 0;
  height: 50px;
  margin-left: 30px;
}


header.headerChatForm-b>h1>div>div {
  font-size: 18px;
  background-color: orangered;
  color: #fff;
  padding: 3px 31px;
  border-radius: 11px;
  height: 27px;
  margin-top: 10px;
  margin-left: 28px;
  letter-spacing: 7px;
  width: 100px;
}

footer {
  text-align: center;
  background-color: peru;
  color: sienna;
}

footer a {
  color: sienna;
  text-decoration: underline;

}

footer a:hover {
  color: tan;
}

div.rsc {
  background-color: #6EC1F5;
  height: 97%;
  background-image: url("../img/cityview.jpeg");
  /* background-image: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url(http://localhost:3000/static/media/cityview.94ee15d….jpeg); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

div.dPdbIW {
  /*チャットフォームの枠の部分*/
  box-shadow: none;
  border-radius: 0;
  margin: 0 auto;
  height: 87%;
  width: 568px;
  background-color: transparent;
  padding-top: 50px;
  z-index: 999;
}

div.fBGGBn {
  height: 100%;
}

div.jKLQUD {
  /*チャットフォームカスタムコンポーネントの枠の部分*/
  margin-left: 60px;
  box-shadow: rgb(0 0 0 / 85%) 1px 1px 3px 1px;
  border-radius: 15px;
  font-size: 16px;
  justify-content: flex-start;
  /* background: none;
  box-shadow: none;
  border: none; */
}

div.jKLQUD div.bukken-type-intro {
  font-weight: 700;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  margin-bottom: 20px;
}

/* div.jKLQUD:nth-child(2) */
.back-white-wrapper {
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 85%) 1px 1px 3px 1px;
}

div.jKLQUD div.back-orange-wrapper {
  /*チャットフォームカスタムコンポーネントの枠の部分*/
  box-shadow: rgb(0 0 0 / 85%) 1px 1px 3px 1px;
  border-radius: 15px;
  font-size: 16px;
  justify-content: flex-start;
  /* background: none;
  box-shadow: none;
  border: none; */
  background-color: orangered;
  display: block;
  padding: 16px;
}

/* div.jKLQUD:nth-child(2) */
div.jKLQUD div.back-orange-wrapper button {
  background-color: white;
  color: black;
  font-weight: 700;
  box-shadow: none;

}

/* div.jKLQUD:nth-child(2) */
div.jKLQUD div.back-orange-wrapper ul button {
  display: grid;
}

div.genkyoSelect {
  width: 100%;
}

div.jKLQUD div.back-orange-wrapper ul.genkyoSelect {
  margin-right: 0;
  width: 100%;
}

div.jKLQUD div.back-orange-wrapper ul.genkyoSelect button {
  display: grid;
  background-color: white;
  color: black;
  font-size: 30px;
  font-weight: 700;
  box-shadow: none;
}

div.jKLQUD div.back-orange-wrapper ul.genkyoSelect li {
  margin-right: 10px;
  margin-left: 10px;
  line-height: 54px;
  box-shadow: 0 4px 0 #434343;
  border: 1px solid #434343;
  border-radius: 22px;
  background-color: white;
  width: 42%;
  height: 70px;
}

div.jKLQUD div.back-orange-wrapper ul.other li {
  box-shadow: 0 4px 0 #434343;
  border: 1px solid #434343;
  border-radius: 22px;
  margin-bottom: 8px;
}

div.jKLQUD div.back-orange-wrapper ul.areaSelect,
div.jKLQUD div.back-orange-wrapper ul.todofukenSelect {
  margin-right: 0;
  width: 100%;
}

div.jKLQUD div.back-orange-wrapper ul.areaSelect button,
div.jKLQUD div.back-orange-wrapper ul.todofukenSelect button {
  display: grid;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: 700;
  box-shadow: none;
}

div.jKLQUD div.back-orange-wrapper ul.areaSelect li,
div.jKLQUD div.back-orange-wrapper ul.todofukenSelect li {
  margin-right: 10px;
  margin-left: 10px;
  line-height: 54px;
  box-shadow: 0 4px 0 #434343;
  border: 1px solid #434343;
  border-radius: 22px;
  background-color: white;
  width: 42%;
  height: 58px;
  margin-bottom: 14px;
}


/* div.jKLQUD:nth-child(2) */
div.jKLQUD div.back-orange-wrapper button img {
  width: 30%;
  margin: 0 auto;
  margin-bottom: 3px;
}

div.jKLQUD div.back-orange-wrapper button.others-button {
  background-color: orangered;
  border: none;
  box-shadow: none;
  margin-top: 10px;
  color: white;
}

div.rsc-footer {
  visibility: hidden;
}

div.hQsUiY {
  /* チャットがわデフォルト吹き出し */
  background-color: #fff;
  color: black;
  box-shadow: rgb(0 0 0 / 85%) 1px 1px 3px 1px;
  font-size: 16px;
}

div.iOCYje {
  /* ユーザー側吹き出し */
  box-shadow: rgb(0 0 0 / 85%) 1px 1px 3px 1px;
  font-size: 16px;
}

img.cityview {
  vertical-align: bottom;
  max-width: 100%;
}

img.rsc-ts-image {
  box-shadow: none;
}

div.cRmLCo {
  background-color: #fff;
  color: black;
  box-shadow: rgb(0 0 0 / 85%) 1px 1px 3px 1px;
  font-size: 16px;
}

ul.gkhNlr {
  /* margin-left: 60px; */
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.bukken-type-box ul.gkhNlr {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-evenly;
  width: 100%;
}

ul.gkhNlr li {
  margin-right: 10px;
  line-height: 54px;
}

.bukken-type-box ul.gkhNlr li {
  margin-right: 10px;
  margin-left: 10px;
  line-height: 54px;
  box-shadow: 0 4px 0 #434343;
  border: 1px solid #434343;
  border-radius: 22px;
  background-color: white;
}
.bukken-type-box ul.gkhNlr li:hover,div.jKLQUD div.back-orange-wrapper ul li:hover,div.akasatanaButton button:hover, div.shikucyouson div button:hover, div.shikucyouson div button:hover, div.cyoiki div button:hover{
  border-color: blue;
  border-width: 2px;
}

button.ipSaXD {
  background-color: orangered;
  color: #fff;
  font-size: 16px;
  border: 0;
  border-radius: 22px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  display: inline-block;
  padding: 12px;
  width: 100%;
}

form.formCss1 {
  width: 100%;
  line-height: 37px;
}

form.formCss1 input {
  width: 80%;
  float: right;
  border-radius: 4px;
  border-color: darkgray;
  margin-top: 4px;
  font-size: 16px;
  padding: 4px;
  border-width: 1px;
  box-shadow: none;
  border-style: solid;
  background-color: transparent;
}

form.bukkenQa2 label input {
  width: 60%;
}

form.formCss1 input[type="radio"] {
  width: 10%;
  float: unset;
}

form.formCss1 select {
  width: 82%;
  float: right;
  border-radius: 4px;
  border-color: darkgray;
  margin-top: 4px;
  font-size: 16px;
  padding: 4px;
}

form.formCss1 button {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-radius: 4px;
  padding: 4px;
  color: #fff;
  background-color: orangered;
  border-width: 0;
  font-size: 16px;
  font-weight: 600;
}
form.formCss1 button:disabled{
 background-color:rgb(200, 193, 193);
}

form.formCss2 button:disabled{
  background-color:rgb(200, 193, 193);
 }
form.propertyDataForm input {
  width: 100%;
}

form.formCss2 {
  width: 100%;
  line-height: 37px;
}

form.formCss2 input {
  width: 71%;
  border-radius: 4px;
  border-color: darkgray;
  margin-top: 4px;
  font-size: 16px;
  padding: 4px;
  border-width: 1px;
  box-shadow: none;
  border-style: solid;
  background-color: transparent;
}

form.formCss2 input.sirnameInput {
  width: 31.5%;
}

form.formCss2 input.firstnameInput {
  width: 31.5%;
  margin-left: 3px;
}

form.formCss2 input[type="radio"] {
  width: 10%;
  float: unset;
}

form.formCss2 input[type="checkbox"] {
  width: 7.5%;
  float: unset;
}

form.formCss2 select {
  width: 72%;
  float: right;
  border-radius: 4px;
  border-color: darkgray;
  margin-top: 4px;
  font-size: 16px;
  padding: 4px;
}

form.formCss2 button {
  width: 100%;
  text-align: center;
  margin-top: 1px;
  border-radius: 4px;
  padding: 5px;
  color: #fff;
  background-color: orangered;
  border-width: 0;
  font-size: 16px;
  font-weight: 600;
}
form.formCss2 ul.contact1c-ul {
  list-style: none;
	padding-left: 0;
	display: flex;
	flex-wrap: wrap;
}

form.formCss2 ul.contact1c-ul li {
  text-align: center;
  margin-top: 0;
  border-radius: 4px;
  padding: 4px;
  border-width: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
}

form.formCss2 ul.contact1c-ul li button {
  text-align: center;
  margin-top: 0;
  border-radius: 14px;
  padding: 4px 9px;
  color: gray;
  background-color: #fff;
  border-width: 1px;
  border-color: silver;
  font-size: 16px;
  font-weight: 600;
}
br.spBr {
  display: none;
}

.form-check br {
  display: none;
}
.dayCheck{
  display: grid;
}
.dayCheck label{
  border: 1px solid darkgray;
  border-radius: 17px;
  margin-bottom: 7px;
}
.dayCheck label:hover{
  border-color: blue;
  border-width: 2px;
}

form.formCss3 {
  width: 100%;
  line-height: 37px;
}

form.formCss3 input {
  width: 10%;
  float: right;
  border-radius: 4px;
  border-color: darkgray;
  margin: 11px;
  font-size: 16px;
  padding: 4px;
  border-width: 1px;
  box-shadow: none;
  border-style: solid;
}

form.formCss3 input[type="radio"] {
  width: 10%;
  float: unset;
}

form.formCss3 select {
  width: 82%;
  border-radius: 4px;
  border-color: darkgray;
  margin-top: 4px;
  font-size: 16px;
  padding: 4px;
}

form.formCss3 button {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 4px;
  color: #fff;
  background-color: orangered;
  border-width: 0;
  font-size: 16px;
  font-weight: 600;
  font-size: 26px;
  letter-spacing: 3px;
}

form.formCss3 div.agentInfo-wrapper {
  border: solid 2px lightslategray;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
}

form.formCss3 label.agent-label {
  display: flex;
}

form.formCss3 h2 {
  font-size: 15px;
  margin: 0;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  margin-left: 12px;
  margin-right: 12px;
  border-bottom: solid 1px black;
}

form.formCss3 p {
  text-align: center;
}

form.formCss3 p.center {
  margin-top: 30px;
}

p.errMsg {
  color: red;
  margin-top: 10px;
}

form.modalForm div.modal-container {
  position: absolute !important;
  margin: auto;
  padding: 0;
  top: 50%;
  left: 50%;
  height: 110%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-40%) translateX(-46%);
  z-index: 1001;
}


form.modalForm div.modal-container div.modal-window {
  width: 453px;
  max-height: 567px;
  overflow-y: auto;
  margin-top: 10%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}

.modal-header{
  text-align: center;
    font-size: 20px;
    color: white;
    padding: 16px 16px 32px 16px;
}
div.jKLQUD div.modal-window div.modal-box {
  overflow-y: auto;
}
div.jKLQUD div.modal-window div.modal-box ul li{
  border-radius: 10px;

}
div.jKLQUD div.modal-window div.modal-box ul li button{
  border-radius: 10px;
  margin-top: 10px;

}
div.modal-window div button.return0p,div.modal-window div button.return00p{
  color: wheat;
  background-color: orangered;
  border: 0.5px wheat solid;
  width: 50px;
  font-size: 9px;
}
div.jKLQUD div.modal-window div.modal-box input{
  width: 98%;
}
div.jKLQUD div.modal-window div.modal-box ul li.whide-li{
  width: 100%;
  margin-bottom: 0;
  border-radius: 0;
}

form.list-form {
  padding: unset;
}

.list-header {
  text-align: center;
  background-color: orangered;
  color: white;
  border-radius: 15px 15px 0 0;
}

.list-header p {
  padding: 16px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 20px;
}

.akasatanaButton {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 16px 16px 16px;
}

div.akasatanaButton button {
  padding: 0.25rem;
  min-width: 56px;
  max-width: none;
  width: auto;
  margin: 6px;
  font-size: 18px;
  line-height: 30px;
  box-shadow: 0 4px 0 #434343;
  border: 1px solid #434343;
  border-radius: 4px;
  background-color: white;
  color: black;
}
div.akasatanaButton button:disabled {
  background-color:rgb(200, 193, 193);
}

div.shikucyouson {
  padding: 4px 8px;
}

div.shikucyouson div {
  height: 360px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 2px solid darkgrey;
  box-sizing: border-box;
}

div.shikucyouson div button {
  font-size: 20px;
  font-weight: bold;
  padding: 0rem 0.5rem;
  border: none;
  border-bottom: 1px solid darkgrey;
  border-top: 1px solid darkgrey;
  margin-right: 0;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  background: transparent;
  outline: none;
  color: #000;
  height: 48px;
  margin-top: 0;
}

div.cyoiki {
  padding: 4px 8px;
}

div.cyoiki div {
  height: 360px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 2px solid darkgrey;
  box-sizing: border-box;
}

div.cyoiki div button {
  font-size: 20px;
  font-weight: bold;
  padding: 0rem 0.5rem;
  border: none;
  border-bottom: 1px solid darkgrey;
  border-top: 1px solid darkgrey;
  margin-right: 0;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  background: transparent;
  outline: none;
  color: #000;
  height: 48px;
  margin-top: 0;
}

div.shikucyouson-f, div.cyoiki-f{
  background-color: #fff;
  border: #000 solid 1px;
}
p.syutten{
  text-align: center;
}


@media screen and (max-width: 1028px) {
  header.headerChatForm {
    position: absolute;
    z-index: 100;
    background-color: white;
    width: 100%;
  }

  header.headerChatForm>h1>div {
    display: flex;
  }

  header.headerChatForm img {
    position: relative;
    top: 0;
    height: 50px;
    margin-left: 30px;
  }

  header.headerChatForm>h1>div>div {
    font-size: 18px;
    background-color: orangered;
    color: #fff;
    padding: 3px 31px;
    border-radius: 11px;
    height: 27px;
    margin-top: 10px;
    margin-left: 28px;
    letter-spacing: 7px;
  }
  header.headerChatForm-b {
    position: absolute;
    z-index: 100;
    background-color: white;
    width: 100%;
  }

  header.headerChatForm-b>h1>div {
    display: flex;
  }

  header.headerChatForm-b img {
    position: relative;
    top: 0;
    height: 50px;
    margin-left: 30px;
  }

  header.headerChatForm-b>h1>div>div {
    font-size: 18px;
    background-color: orangered;
    color: #fff;
    padding: 3px 31px;
    border-radius: 11px;
    height: 27px;
    margin-top: 10px;
    margin-left: 28px;
    letter-spacing: 7px;
  }

  div.dPdbIW {
    /*チャットフォームの枠の部分*/
    padding-top: 100px;
  }
}

@media screen and (max-width: 568px) {
  footer {
    font-size: 10px;
  }

  div.jKLQUD {
    margin-left: 0;
  }

  div.jKLQUD div.bukken-type-intro {
    font-size: 15px;
    margin-left: 0;
    margin-right: 0;
  }

  /* div.jKLQUD:nth-child(2) */
  div.jKLQUD div.back-orange-wrapper {
    margin-left: 5px;
  }
 

  .back-white-wrapper {
    margin-left: 5px;
    box-shadow: rgb(0 0 0 / 85%) 1px 1px 3px 1px;
  }

  .bukken-type-box ul.gkhNlr li {
    margin-right: 2px;
    margin-left: 2px;
  }

  div.jKLQUD div.back-orange-wrapper ul.areaSelect button,
  div.jKLQUD div.back-orange-wrapper ul.todofukenSelect button {
    font-size: 15px;
  }

  div.jKLQUD div.back-orange-wrapper ul.areaSelect li,
  div.jKLQUD div.back-orange-wrapper ul.todofukenSelect li {
    margin-right: 8px;
    margin-left: 8px;
    width: 44%;
    height: 50px;
  }

  .spBr {
    visibility: visible;
  }

  header.headerChatForm>h1>div>div {
    padding: 3px 8px 3px 27px;
    letter-spacing: 3px;
  }
  header.headerChatForm-b>h1>div>div {
    padding: 3px 8px 3px 27px;
    letter-spacing: 3px;
  }

  div.dPdbIW {
    bottom: 0 !important;
    left: initial !important;
    height: 85%;
    right: 0 !important;
    top: initial !important;
    width: 100%;
  }

  div.jKLQUD {
    /* margin-right: 60px; */
  }

  form.formCss1 input {
    width: 97%;
  }

  form.formCss1 select {
    width: 100%;
  }

  form.formCss2 input {
    width: 97%;
  }

  form.formCss2 select {
    width: 100%;
  }

  form.formCss2 input.sirnameInput {
    width: 44.5%;
  }

  form.formCss2 input.firstnameInput {
    width: 44.5%;
  }

  br.spBr {
    display: block;
  }

  .form-check br.checkBoxBr3,
  .form-check br.checkBoxBr6 {
    display: block;
  }
  .dayCheck br.checkBoxBr3,
  .dayCheck br.checkBoxBr6 {
    display: none;
  }
  
  form.formCss2 input {
    width: 100%;
    float: inherit;
  }
  div.shikucyouson div {
    height: 280px;
  }
  div.cyoiki div {
    height: 280px;
  }
  .modal-header{
    font-size: 18px;
  }
  form.modalForm div.modal-container {
    left: 45%;
    height: 100%;
  }
  form.modalForm div.modal-container div.modal-window {
    width: 89vw;
    max-height: 506px;
  }
}
